import { React } from 'react'
import "./src/styles/global.css"

export const onClientEntry = () => {
  const consent = localStorage.getItem("cookieConsent");
  if (consent === "granted") {
    loadGoogleAnalytics();
  }
};

const loadGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag("js", new Date());
  gtag("config", "G-VRLXKPWL5B", { anonymize_ip: true });
};
